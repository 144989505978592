<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-ground-banner with-route-bar">
      <div class="items">
        <div
          class="item"
          :style="{
            'background-image': `url(${require('@/assets/img/fo/bg-1.jpg')})`
          }"
        >
          <span v-html="$t('public.upgrade_header')"></span>
        </div>
      </div>
    </section>

    <section class="section section-inner">
      <div class="container m-container-only-s">
        <div class="content-cols">
          <div class="left-col">
            <img src="@/assets/img/hero-upgrade.png" alt="" />
            <p>
              {{ $t("public.upgrade_text_1") }}
            </p>
          </div>
          <div class="legals">
            <p>
              {{ $t("public.upgrade_text_2") }}
            </p>
          </div>
          <div class="right-col">
            <h1>{{ $t("public.upgrade_text_3") }}<span class="sup">*</span></h1>

            <p>
              {{ $t("public.upgrade_text_4") }}
            </p>
            <p>
              {{ $t("public.upgrade_text_5") }}
            </p>
            <p>
              {{ $t("public.upgrade_text_6") }}
            </p>

            <ol>
              <li>
                <h3>
                  {{ $t("public.upgrade_text_7") }}
                </h3>
                <p>
                  {{ $t("public.upgrade_text_8") }}
                  <img src="@/assets/i/nav-red.svg" alt="" class="nav-icon" />
                </p>
                <img
                  src="@/assets/img/upgrade-img-1.png"
                  class="screen"
                  alt=""
                />
                <p>
                  {{ $t("public.upgrade_text_9") }}
                </p>
              </li>
              <li>
                <h3>
                  {{ $t("public.upgrade_text_10") }}
                </h3>
                <img
                  src="@/assets/img/upgrade-img-2.png"
                  class="screen small"
                  alt=""
                />
              </li>
              <li>
                <h3>
                  {{ $t("public.upgrade_text_11") }}
                </h3>
                <p>
                  {{ $t("public.upgrade_text_12") }}
                  <img src="@/assets/i/nav-red.svg" alt="" class="nav-icon" />
                </p>
                <img
                  src="@/assets/img/upgrade-img-3.png"
                  class="screen"
                  alt=""
                />
                <p>
                  {{ $t("public.upgrade_text_13") }}
                </p>
              </li>
              <li>
                <h3>
                  {{ $t("public.upgrade_text_14") }}
                </h3>
                <p>
                  {{ $t("public.upgrade_text_15") }}
                  <img src="@/assets/i/nav-red.svg" alt="" class="nav-icon" />
                </p>
                <img
                  src="@/assets/img/upgrade-img-4.png"
                  class="screen"
                  alt=""
                />
                <ul>
                  <li>
                    {{ $t("public.upgrade_text_16") }}
                  </li>
                  <li>
                    {{ $t("public.upgrade_text_17") }}
                  </li>
                  <li>
                    {{ $t("public.upgrade_text_18") }}
                  </li>
                  <li>
                    {{ $t("public.upgrade_text_19") }}
                  </li>
                </ul>
              </li>
              <li>
                <h3>
                  {{ $t("public.upgrade_text_20") }}
                </h3>
                <img
                  src="@/assets/img/upgrade-img-5.png"
                  class="screen"
                  alt=""
                />
                <p>
                  {{ $t("public.upgrade_text_21") }}
                </p>
                <p>
                  {{ $t("public.upgrade_text_22") }}
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "UpgradeSeatPublic",
  title() {
    return this.$t("pages.upgrade_seat");
  },
  computed: {
    ...mapState("flight", {
      flightDetailsLoading: state => state.flightDetailsLoading,
      flightDetails: state => state.flightDetails,
      flightLoading: state => state.flightLoading,
      flight: state => state.flight,
      planeLoading: state => state.planeLoading,
      planeAirSeats: state => state.planeAirSeats,
      planeType: state => state.planeType
    }),
    ...mapState("order", {
      layoutLoading: state => state.layoutLoading
    })
  },
  methods: {
    ...mapActions("flight", {
      getPlane: "plane",
      updateSeat: "updateSeat"
    }),
    ...mapActions("order", {
      actionLayout: "layout",
      updateOrder: "updateOrderState"
    })
  }
};
</script>

<style scoped></style>
